// newsletter.duck.js

import { usertype } from "../../config/configListing";
import { addPersonToNewsLetter, searchForPersonInNewsletter } from "../../util/api";

// Action Types
const SUBSCRIBE_REQUEST = 'newsletter/SUBSCRIBE_REQUEST';
const SUBSCRIBE_SUCCESS = 'newsletter/SUBSCRIBE_SUCCESS';
const SUBSCRIBE_FAILURE = 'newsletter/SUBSCRIBE_FAILURE';
const SET_FORM_DATA = 'newsletter/SET_FORM_DATA';
const OPEN_MODAL = 'newsletter/OPEN_MODAL';
const CLOSE_MODAL = 'newsletter/CLOSE_MODAL';

// Action Creators
export const subscribeRequest = () => ({
  type: SUBSCRIBE_REQUEST,
});

export const subscribeSuccess = () => ({
  type: SUBSCRIBE_SUCCESS,
});

export const subscribeFailure = (error) => ({
  type: SUBSCRIBE_FAILURE,
  payload: error,
});

export const setFormData = (formData) => ({
  type: SET_FORM_DATA,
  payload: formData,
});

export const openModal = () => ({
  type: OPEN_MODAL,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});

// Thunk Action Creator
export const subscribeToNewsletter = (formData) => async (dispatch) => {
  dispatch(subscribeRequest());

  try {
    const { email, name, userType, Location } = formData;
    console.log(userType.value, 'userType');
    const searchResponse = await searchForPersonInNewsletter(email);
    console.log('searchResponse', searchResponse);  
    if (searchResponse.emailExists ) {
      // Person found, handle response as needed
      dispatch(subscribeFailure('Already subscribed'));
    } else {
      const requestBody = {
        name,
        email,
        lifecyclestage: 'subscriber',
        userType : userType.value,
      };

      // if (userType.value === 'perinatalCenter') {
      //   requestBody.Location = Location;
      // }

      const addresponse = await addPersonToNewsLetter(requestBody);
      console.log(addresponse, 'addresponse');
      dispatch(subscribeSuccess());

      // Set cookies after successful subscription
      const now = new Date().getTime();
      document.cookie = 'hasSubscribed=true; path=/';
      document.cookie = `lastModalClosedTime=${now}; path=/`;
    }
  } catch (error) {
    dispatch(subscribeFailure(error.message));
  }
};

// Initial State
const initialState = {
  loading: false,
  subscribed: false,
  error: null,
  formData: {
    name: '',
    email: '',
  },
  isOpen: false,
};

// Reducer
export default function newsletterReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SUBSCRIBE_REQUEST:
      return { ...state, loading: true, error: null };
    case SUBSCRIBE_SUCCESS:
      return { ...state, loading: false, subscribed: true };
    case SUBSCRIBE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case SET_FORM_DATA:
      return { ...state, formData: action.payload };
    case OPEN_MODAL:
      return { ...state, isOpen: true };
    case CLOSE_MODAL:
      return { ...state, isOpen: false };
    default:
      return state;
  }
}

// Selectors
export const selectLoading = (state) => state.newsletter.loading;
export const selectSubscribed = (state) => state.newsletter.subscribed;
export const selectError = (state) => state.newsletter.error;
export const selectFormData = (state) => state.newsletter.formData;
export const selectIsOpen = (state) => state.newsletter.isOpen;