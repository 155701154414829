import moment from 'moment';
export const checkMembership = user => {
  const { attributes } = user || {};
  const { profile } = attributes || {};
  const { metadata } = profile || {};

  const { current_period_end, membership, plan } = metadata || {};
  if (!current_period_end) return false;
  const subScriptionEndDate = new Date(current_period_end * 1000);
  const isEndDateInPast = subScriptionEndDate < new Date();
  const isSubscriptionActive = !isEndDateInPast;
  return isSubscriptionActive;
};
