import React from 'react';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { Button, NamedLink } from '../../components';

import css from './ModalMissingInformation.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfileWhenSkip } from '../../ducks/user.duck';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ListingAvailabilityReminder = props => {
  const { className, anyListingHaveAvailability } = props;
  const state = useSelector(state => state);
  const { updateProfileInProgress, updateProfileError } = state.user;
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { location } = history || {};
  const { pathname } = location || {};
  return (
    <div className={className}>
      <p className={css.modalTitle}>
        <FormattedMessage id="ModalMissingInformation.ListingAvailabilityReminder.missingAvailabilty" />
      </p>
      <p className={css.modalMessage}>
        {intl.formatMessage({
          id: 'ModalMissingInformation.ListingAvailabilityReminder.missingAvailabiltyText',
        })}
      </p>
      <br />
      <NamedLink className={css.reminderModalLinkButton} name="ManageListingsPage">
        {intl.formatMessage({
          id: 'ModalMissingInformation.ListingAvailabilityReminder.setAvailability',
        })}
      </NamedLink>

      {anyListingHaveAvailability ? (
        <>
          <br />
          <Button
            inProgress={updateProfileInProgress}
            onClick={() => {
              dispatch(updateProfileWhenSkip()).then(() => {
                history.push('/');
              });
            }}
            type="button"
          >
            {intl.formatMessage({ id: 'ModalMissingInformation.ListingAvailabilityReminder.skip' })}
          </Button>
        </>
      ) : null}
    </div>
  );
};

export default ListingAvailabilityReminder;
