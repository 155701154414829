/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, FieldTextInput, FieldLocationAutocompleteInput } from '../../components';
import css from './Newsletter.module.css';
import { useDispatch, useSelector } from 'react-redux';
import logo from './newsletter_new.png';
import * as validators from '../../util/validators';
import {
  subscribeToNewsletter,
  selectLoading,
  selectSubscribed,
  selectError,
  openModal,
  closeModal,
  selectIsOpen,
} from './newsletter.duck';
import { FormattedMessage } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import FieldSelectModern from '../FieldSelectModern/FieldSelectModern';
import { usertypeNewsletter } from '../../config/configListing';

const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
  console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
};

const Newsletter = (props) => {
  const { intl } = props;
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const subscribed = useSelector(selectSubscribed);
  const error = useSelector(selectError);
  const isOpen = useSelector(selectIsOpen);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    const cookies = document.cookie.split(';').reduce((acc, cookie) => {
      const [name, value] = cookie.trim().split('=');
      acc[name] = value;
      return acc;
    }, {});

    const storedHasSubscribed = cookies.hasSubscribed;
    const storedLastModalClosedTime = cookies.lastModalClosedTime;

    const hasSubscribed = storedHasSubscribed === 'true';
    const lastModalClosedTime = storedLastModalClosedTime ? parseInt(storedLastModalClosedTime, 10) : null;

    if (!isAuthenticated && !hasSubscribed) {
      const now = new Date().getTime();
      const tenDaysInMs = 10 * 24 * 60 * 60 * 1000;

      if (lastModalClosedTime === null || now - lastModalClosedTime >= tenDaysInMs) {
        setTimeout(() => {
          dispatch(openModal());
        }, 10000);
      }
    }
  }, [dispatch, isAuthenticated]);

  const handleClose = () => {
    dispatch(closeModal());
    const now = Date.now();
    document.cookie = `lastModalClosedTime=${now}; path=/`;
  };

  const onSubmit = (values) => {
    const name = `${values.fname} ${values.lname}`.trim();
    const updatedFormData = {
      ...values,
      name,
    };
    dispatch(subscribeToNewsletter(updatedFormData));
  };

  // email
  const emailRequired = validators.required(
    intl.formatMessage({
      id: 'SignupForm.emailRequired',
    })
  );
  const emailValid = validators.emailFormatValid(
    intl.formatMessage({
      id: 'SignupForm.emailInvalid',
    })
  );

  const addressRequiredMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressRequired',
  });
  const addressNotRecognizedMessage = intl.formatMessage({
    id: 'EditListingLocationForm.addressNotRecognized',
  });

  const form = subscribed ? (
    <p><FormattedMessage id="Newsletter.thanksLabel" /></p>
  ) : (
    <FinalForm
      onSubmit={onSubmit}
      render={({ handleSubmit, values, formId }) => (
        <Form onSubmit={handleSubmit}>
          <div className={css.name}>
            <FieldTextInput
              className={css.firstNameRoot}
              type="text"
              id={formId ? `${formId}.fname` : 'fname'}
              name="fname"
              autoComplete="given-name"
              label={intl.formatMessage({
                id: 'SignupForm.firstNameLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.firstNamePlaceholder',
              })}
              validate={validators.required(
                intl.formatMessage({
                  id: 'SignupForm.firstNameRequired',
                })
              )}
            />
            <FieldTextInput
              className={css.lastNameRoot}
              type="text"
              id={formId ? `${formId}.lname` : 'lname'}
              name="lname"
              autoComplete="family-name"
              label={intl.formatMessage({
                id: 'SignupForm.lastNameLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.lastNamePlaceholder',
              })}
              validate={validators.required(
                intl.formatMessage({
                  id: 'SignupForm.lastNameRequired',
                })
              )}
            />
          </div>
          <FieldTextInput
            className={css.email}
            type="email"
            id={formId ? `${formId}.email` : 'email'}
            name="email"
            autoComplete="email"
            label={intl.formatMessage({
              id: 'SignupForm.emailLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'SignupForm.emailPlaceholder',
            })}
            validate={validators.composeValidators(emailRequired, emailValid)}
          />
          
          <FieldSelectModern
            className={css.modern}
            id="userType"
            label={<FormattedMessage id="SignupForm.userTypeLabel" />}
            name="userType"
            options={usertypeNewsletter.map(ele => ({
              ...ele,
              label: <FormattedMessage id={ele.label} />,
            }))}
            validate={validators.required(
              <FormattedMessage id="SignupForm.usertypeValidation" />
            )}
            isSearchable={false}
          />
          {(values?.userType?.value === 'professional' || values?.userType?.value === 'perinatal_center') && (
            <FieldLocationAutocompleteInput
            rootClassName={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            name="Location"
            label={<FormattedMessage id="SignupForm.addressLabel" />}
            placeholder={intl.formatMessage({
              id: 'SignupForm.addressPlaceholder',
            })}
            useDefaultPredictions={false}
            validate={validators.composeValidators(
              validators.autocompleteSearchRequired(addressRequiredMessage),
              validators.autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
            hideIcon={true}
          />
          )}
          <Button className={css.button} inProgress={loading}>
            <FormattedMessage id="Newsletter.subscribeButton" />
          </Button>
          {error && <div className={css.errorMessage}>{error}</div>}
        </Form>
      )}
    />
  );

  return (
    <Modal
      {...props}
      id="newsletter"
      isOpen={isOpen}
      onClose={handleClose}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.container}>
        <img src={logo} alt="Newsletter" className={css.image} />
        {form}
      </div>
    </Modal>
  );
};


export default Newsletter;